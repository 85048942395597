<template>
    <div class="statistics">

        <div class="item" v-for="(item,index) in list" :key="index">
            <div class="left">
                <img :src="item.img" alt="">
            </div>

            <div class="right">
                <div class="title">{{item.title}}</div>
                <div class="sum"> {{ $store.state.config[item.count] }} {{item.dw}}</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: '',
    data () {
        return {
            list: [
                {
                    img: require('@/assets/images/home/s1.png'),
                    title: '今日入库',
                    count: 'tstorage',
                    dw: '笔',
                },
                {
                    img: require('@/assets/images/home/s2.png'),
                    title: '平台把控',
                    count: 'pstorage',
                    dw: '笔',
                },
                {
                    img: require('@/assets/images/home/s3.png'),
                    title: '累计入库',
                    count: 'astorage',
                    dw: '笔',
                },
            ],
        };
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.statistics {
    width: 1250px;
    height: 250px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;

    border-radius: 30px;
    box-shadow: 0 0 15px #ccc;
    .item {
        height: 110px;
        display: flex;

        justify-content: flex-start;
        align-items: center;

        .right{
            margin-left: 30px;
            .title{
                font-size: 26px;
                color: #0100a1;
            }
            .sum{
                font-size: 20px;
                color: #757575;
                margin: 10px 0;
            }
        }


    }
}
</style>