<template>
    <div class="cont">
        <div class="banner"></div>

        <div class="card">
            <div class="card-item" v-for="(item,index) in cardList" :key="index">
                <img :src="item.img" alt="">
                <div class="title"> {{item.title }}</div>
                <div class="text">{{item.text }}</div>
            </div>
        </div>

        <div class="baohan">
            <a href="http://www.cloud-sun.com.cn/" target="_blank">
                <div class="btn">
                    保函办理平台
                    <i class="el-icon-sort-up jiantou"></i>
                </div>
            </a>

            <div class="mzsm">
                免责声明：本平台仅对入驻机构资质进行审核，并提供技术支持。所有保函销售、担保责任均由对应保证人承担。
            </div>

        </div>

        <div class="dzbh">

            <!-- 66px -->
            <div class="title">
                <!-- 电子保函：专业解决方案提供商 -->
            </div>
            <div class="text">
                致力于推动电子、银行保函业务在全国招投标领域的普及和实施；
                服务于工程招投标信息提供、交易撮合业务领域；
                整合包括建行、民生行、兴业银行、张家囗银行在内的多家银行提供保函渠道；
                根据企业资质、工程状况等因素为企业设计最佳银行保函出具方案；
                根据最终确定的出具银行智能选择相应的担保公司，
                为投标人提供专业高效的一站式锒行保函办理服务……
            </div>

            <!-- 31px -->
        </div>

    </div>
</template>

<script>
export default {
    name: '',
    data () {
        return {
            cardList: [
                {
                    title: '用印时间',
                    text: '精确到年月日时分秒',
                    img: require('@/assets/images/home/c1.png'),
                },
                {
                    title: '用印人信息',
                    text: '获取CA锁的信息',
                    img: require('@/assets/images/home/c2.png'),
                },
                {
                    title: '人工智能',
                    text: 'ArtificialInteIligence',
                    img: require('@/assets/images/home/c3.png'),
                },
                {
                    title: '区块链',
                    text: 'Blockchain',
                    img: require('@/assets/images/home/c4.png'),
                },

            ],
        };
    },
    mounted () {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.cont {
    width: 100%;
    // height: 2500px;
    // position: relative;

    .banner {
        width: 100%;
        height: 1071px;
        // border: 1px solid red;
        background: url('@/assets/images/home/home-cont.png') no-repeat;
        background-size: cover;
    }

    .card {
        width: 1440px;
        height: 370px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        // border: 1px solid red;
        // position: asb;
        margin-top: -300px;

        .card-item {
            width: 310px;
            height: 350px;
            box-sizing: border-box;
            padding: 40px 0;
            border-radius: 30px;
            box-shadow: 0 0 25px #adadad;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: #fff;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }

            img {
                width: 100px;
                height: 110px;
            }
            &:first-child img {
                height: 90px;
                margin-bottom: 20px;
            }

            .title {
                font-size: 36px;
                color: #131415;
            }
            .text {
                font-size: 26px;
                color: #6d6d6d;
            }
        }
    }

    .baohan {
        width: 1375px;
        height: 411px;
        margin: 30px auto;
        margin-bottom: 65px;
        background: url('@/assets/images/home/baohan-bg.png');
        position: relative;
        .btn {
            width: 280px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            background-color: #0100a1;
            border-radius: 40px;
            font-size: 24px;
            color: #fff;
            cursor: pointer;
            position: absolute;
            right: 200px;
            top: 160px;

            .jiantou {
                transform: scaleY(-1) rotateZ(90deg);
                // transform:;
            }

            &:hover {
                color: #ffb73b;
            }
        }
        .mzsm{
            width: 1000px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            margin: 0 auto;
            font-size: 16px;
            position: absolute;
            bottom: -35px;
            right: calc(375px / 2);
        }
    }

    .dzbh {
        width: 100%;
        height: 477px;
        background: url('@/assets/images/home/dzbh-bg.png') no-repeat;
        background-size: cover;
        margin-top: 50px;
        border-top: 1px solid #fff;

        .title {
            margin: 70px auto 50px;
            width: 921px;
            height: 74px;
            // padding: 50px 0;

            // font-size: 64px;
            // color: #fff;
            // text-align: center;
            // transform: skew(-10deg, 0deg);
            // text-shadow: 5px 5px 10px #000;
            background: url('@/assets/images/home/dzbh-text.png') no-repeat;
        }
        .text {
            font-size: 31px;
            color: #fff;
            width: 1524px;
            margin: 0 auto;
        }
    }
}
</style>
