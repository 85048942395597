<template>
    <div class="banner">
        <div class="nav-box">
            <img src="@/assets/images/home/logo.png" alt="">
            <div class="nav">

                <div class="header">
                    <div class="nav-list">
                        <div class="nav-con">
                            <template v-for="(item,index) in $store.state.config.nav">
                                <a v-if="item.show" :key="index" class="nav-item" :href="item.href">
                                    {{item.title}}
                                </a>
                            </template>
                        </div>
                    </div>
                </div>

            </div>

            <div class="login">
                <user-login @userInfo="getuserInfo" logotag="1"></user-login>
            </div>
        </div>

        <!-- 搜索框 -->
        <search-input class="header-search-box" @dzdialog="searchHandler" :indexbhtype="bhtype" @loginshow="loginshow"
            :userInfo="userInfo"></search-input>

        <!-- 文字 -->
        <img class="banner-text" src="@/assets/images/home/banner-text.png" alt="">

        <!-- 二维码 -->
        <div class="qrcode">

            <div class="title">担保机构客服</div>

            <div class="home-organization-section">
                <ul>
                    <li><img src="@/assets/images/home/微信1.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信2.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信3.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信4.png" alt=""></li>

                    <li><img src="@/assets/images/home/微信1.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信2.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信3.png" alt=""></li>
                    <li><img src="@/assets/images/home/微信4.png" alt=""></li>
                </ul>
                <div style="clear:both;"></div>
            </div>

        </div>

        <!-- 相关弹窗 -->
        <el-dialog title="保险公司选择" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false"
            :before-close="handleClose">
            <ul class="bxgs">
                <li><a @click="zhdialog=true">中华联合财产保险股份有限公司</a></li>
                <li><a href="https://www.95505.com.cn/customer_service/electronic_policy.html"
                        target="_blank">天安财产保险股份有限公司河北省分公司</a></li>
            </ul>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="zhdialog" width="800px" :close-on-click-modal="false">
            <div class="zhdialog">
                <h3>请选择其他险->其他非车险进行查验</h3>

                <img src="@images/bhqueryYd.png" alt="" style="width:100%" />
                <div style="width:100%;text-align:center;">
                    <el-button type="warning" @click="gozh()">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <el-dialog class="search-mobile-input" title="输入手机号、验证码以进行查询" :visible.sync="logindialog" width="500px"
            :close-on-click-modal="false">
            <el-input v-model="yzmform.extmobile" placeholder="请输入手机号">
                <el-button slot="append" type="text" style="padding:0 10px;" :loading="loadingController"
                    :disabled="inputDisabled" @click="getyzm()">
                    {{text}}
                </el-button>
            </el-input>
            <el-input type="text" placeholder="请输入手机验证码" v-model="yzmform.validate" style="margin-top:20px;"></el-input>
            <div class="btn-row">
                <el-button type="success" @click="login()" icon="el-icon-search">查询</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import UserLogin from '@components/pages/user-login';
import SearchInput from '@components/pages/index/search-input';
import { mapGetters, mapMutations, mapActions } from 'vuex';



export default {
    name: '',
    components: {
        UserLogin,
        SearchInput,
    },
    data: () => ({
        inputDisabled: false,
        loadingController: false,
        text: '发送验证码',
        logindialog: false,
        userInfo: '',
        zhdialog: false,
        dialogVisible: false,
        bhtype: '',
        loading: true,
        curHeight: '',
        pgData: {
            title: '',
            type: '',
            citycode: '',
            amount: '',
        },
        options: [
            { label: '电子担保保函', value: '1' },
            { label: '电子保险保函', value: '2' }
        ],
        yzmform: {
            extmobile: '',
            validate: '',
            extloginname:'',
            extpwd:'',
        },
        form: {}
    }),
    computed: {
        serviceList () {
            return [
                ...this.$store.state.hzjglist,
                ...this.$store.state.hzjglist,
            ];
        },
    },
    methods: {
        ...mapMutations({
            updateUserInfo: 'userinfo/updateUserInfo',
        }),

        getuserInfo (data) {
            this.userInfo = data;
        },
        login () {
            if (this.yzmform.extmobile || this.yzmform.validate) {

                this.$post(`${this.$store.state.api.sysUrl}/user/phonelogin.json`,this.yzmform, function (data, res) {
                            // ShowMsg(res.msg,'success');
                            // window.location.assign('/');
                          
                            this.updateUserInfo(data);
                             this.goto({
                                path: '/guarantee/bh',
                                query: {
                                    guaranteecode: this.form.guaranteecode,
                                    querycode: this.form.querycode,
                                    phonenum: this.yzmform.extmobile,
                                    validatecode: this.yzmform.validate
                                }
                            });
                });

            } else {
                ShowMsg('手机号或验证码不能为空');
            }
        },
        getyzm () {
            if (!this.yzmform.extmobile || !/^[1][0-9]{10}$/.test(this.yzmform.extmobile)) {
                ShowMsg('请确认手机号', 'warning');
                return;
            };
            this.$post(`${this.$store.state.api.sysUrl}/user/sendyzm.json`, {
                phonenum: this.yzmform.extmobile
            }, data => {
                //倒计时
                this.loadingController = true;
                this.inputDisabled = true;
                //倒计时
                var remain = 60;
                this.text = '请在' + remain + 's后重试';

                var int = setInterval(() => {
                    if (remain != 0) {
                        remain--;
                        this.text = '请在' + remain + 's后重试';
                    } else {
                        clearInterval(int);
                        this.text = '获取手机验证码';
                        this.inputDisabled = false;
                        this.loadingController = false;
                    }
                }, 1000);

            });
        },
        loginshow (data) {
            if (data && this.userInfo === '1') {
                this.logindialog = true;
                this.form = data;
            }
        },
        gozh () {
            window.open("http://query.cic.cn/autoclaim/epiq/main.jsp");
        },
        handleClose () {
            if (this.bhtype === '2') {
                ShowConfirm('该类型必须选择保险公司，确认关闭吗', 'warning', () => {
                    this.dialogVisible = false;
                    this.bhtype = '1';
                });
            }
        },
        isActive (href) {
            return href === this.$route.path ? 'active' : '';
        },
        searchHandler (data) {
            this.bhtype = data;
            if (data === '2') {
                this.dialogVisible = true;
            }
        },

        ...mapMutations({
            updateStorage: 'updateStorage',
        }),

        ...mapActions({
            queryStorage: 'queryStorage',
        }),

    },
    created () {

    },
    mounted () {


        this.queryStorage();
    },
    destroyed () {
        // window.onresize = null;
    }
}
</script>

<style lang="scss" scoped>
$navHeight: 70px;

.banner {
    width: 100%;
    height: 840px;
    background: url('@/assets/images/home/banner.jpg') no-repeat;

    background-size: cover;
    .nav-box {
        padding: 10px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        // 导航
        .header {
            width: 800px;
            height: $navHeight;
            position: relative;

            // .headerbk {
            //     width: 50%;
            //     height: $navHeight;
            //     // background: #4988cf;
            //     // position: absolute;
            //     // left: 0;
            //     // bottom: 0;
            // }
            .nav-list {
                width: 100%;
                height: $navHeight;
                margin: 0 auto;
                font-size: 28px;
                overflow: hidden;
                position: relative;

                .nav-con {
                    // background: #4988cf;
                    padding-right: 70px;
                    height: $navHeight;
                    display: inline-block;
                    position: relative;
                }

                .nav-item {
                    float: left;
                    cursor: pointer;
                    color: #ffffff;
                    position: relative;
                    font-size: inherit;
                    padding: 15px 10px;
                    margin: 0 10px;
                    transition: 0.1s all;
                    line-height: 1em;
                    box-sizing: border-box;

                    // & + a {
                    //     margin-left: 110px;
                    // }

                    &:hover {
                        border-bottom: 4px solid #fff;
                    }
                    &.active {
                        font-weight: bolder;
                        border-bottom: 4px solid #fff;
                    }
                }
            }
        }
    }

    .header-search-box {
        margin-top: 34px;
        width: 650px;
        position: relative;
        left: 126px;
        top: 70px;
    }
    .banner-text {
        position: relative;
        left: 126px;
        top: 130px;
    }

    .qrcode {
        position: absolute;
        right: 10px;
        top: 670px;
        // border: 1px solid red;

        .title {
            font-size: 24px;
            color: #fff;
            text-align: center;
        }

        img {
            width: 114px;
            height: 114px;
            margin-right: 35px;

            // position: relative;
            // left: 126px;
            // top: 200px;
            // top: 40vh;
        }

        .home-organization-section {
            display: flex;
            // width: 320px;
            min-width: 300px;
            width: calc( 100vh - 620px);
            max-width: 600px;
            padding: 10px 0;
            box-shadow: 0px 3px 14px 0px rgba(135, 140, 151, 0.08);
            overflow: hidden;
            // border: 1px solid red;

            ul {
                display: flex;
                margin: 0 auto;
                animation: flowing 20s infinite linear;
                cursor: pointer;
                &:hover {
                    animation-play-state: paused;
                }

                li {
                    margin-right: 40px;
                    img {
                        width: 114px;
                        height: 114px;
                        display: inline-block;
                    }
                    img:hover {
                        transition: 0.3s all;
                        transform: scale(1.1);
                    }
                }
            }
        }
        // }
    }

    // 弹窗

    .bxgs {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-content: center;

        li {
            flex: 1;
            margin: 0 20px;
            min-height: 40px;
            text-align: center;
            border: 1px solid #ffb73b;

            a {
                width: 100%;
                height: 100%;
                display: block;
                color: #ffb73b;
                padding: 0.5em 1em;
                font-size: 16px;
            }
            a:hover {
                background: #ffb73b;
                color: #ffffff;
            }
        }
    }
    .zhdialog {
        text-align: center;
        h3 {
            margin-bottom: 10px;
        }
    }

    .search-mobile-input {
        .btn-row {
            margin-top: 20px;

            ::v-deep {
                .el-button {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes flowing {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}
</style>