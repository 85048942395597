<template>
    <div class="home">
        <nav-banner></nav-banner>

        <!-- 统计 -->
        <statistics class="my-sta"></statistics>

        <!-- 主内容 -->
        <home-content></home-content>

      
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import navBanner from '@/pages/home/section/nav-banner.vue';
import statistics from '@/pages/home/section/statistics.vue';
import homeContent from '@/pages/home/section/home-content.vue';


export default {
    head: {
        title: '中国电子保函网'
    },
    components: {
        navBanner,
        statistics,
        homeContent,
    },
    data: () => ({
        staTop: '',
    }),
    computed: {
        serviceList () {
            return [
                ...this.$store.state.hzjglist,
                ...this.$store.state.hzjglist,
            ];
        },
    },
    methods: {
    },
    created () {
        
    },
    mounted () {
        
    },
    destroyed () {
       
    }
}
</script>

<style lang="scss" scoped>
@import '@css/var.scss';

$navHeight: 70px;

.home {
    min-width: 1920px;
    width: 100%;
    height: 100%;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    font-family: Microsoft YaHei;
    position: relative;
    // overflow-x: hidden;

    .my-sta {
        position: absolute;
        z-index: 99;
        top: 770px;
        left: calc(50% - 625px);
    }

}
</style>
