<template>

    <div class="search-box">
        <div class="search-input">

            <el-select v-model="bhtype" placeholder="请选择保函类型" @change="setbhlx" style="width:170px;">
                <el-option v-for="item in options" :key="item.value" :label="item.label"
                    :value="item.value"></el-option>
            </el-select>

            <el-input placeholder="请输入保函编号" v-model="form.guaranteecode" style="width:220px;"
                class="search-bor"></el-input>

            <el-input placeholder="请输入备案码" v-model="form.querycode" style="width:180px;float:right;">
            </el-input>

        </div>

        <!-- <el-button slot="append" type="primary" icon="el-icon-search" @click="search()">
            查询
        </el-button> -->
        <div class="search-btn" @click="search">查询</div>

    </div>
</template>

<script>
import {  mapMutations , mapGetters } from 'vuex';


export default {
    data () {
        return {
            options: [
                { label: '电子担保保函', value: '1' },
                { label: '电子保险保函', value: '2' }
            ],
            bhtype: '1',
            form: {
                guaranteecode: '',
                querycode: ''
            },
        };
    },
    watch: {
        indexbhtype: function () {
            if (this.indexbhtype === '1') {
                this.bhtype = '1';
            } else if (this.indexbhtype === '2') {
                this.bhtype = '2';
            }
        }
    },
    computed: {
        // loginStatus
        ...mapGetters({
            loginStatus: 'userinfo/loginStatus',
            getUserInfo: 'userinfo/getUserInfo',
        })
    },

    props: ['indexbhtype', 'userInfo'],
    methods: {

        setbhlx (code) {
            if (code === '2') {
                this.$emit('dzdialog', code);
            }
        },
        search () {
            if (this.form.guaranteecode === '' || this.form.querycode === '') {
                ShowMsg('请输入保函编号或备案码');
            // } else if (this.userInfo === '1') {
            } else if (!this.loginStatus) {
                this.$emit('loginshow', this.form);
            } else {
                window.location.href = "/guarantee/bh?guaranteecode=" + this.form.guaranteecode + "&querycode=" + this.form.querycode;
            }
        }
    },
    mounted () {

    }
}
</script>

<style lang="scss" scoped>
@import '~@css/var.scss';

.search-box {
    display: flex;
    // border: 1px solid red;
    justify-content: space-between;
    align-items: center;
}
.search-btn {
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    &:hover{
        color: #FFB73B !important;
    }
}

.search-input {
    width: 580px;
    height: 62px;
    box-shadow: 0px 3px 10px 0px rgba(138, 147, 168, 0.16);
    display: flex;
    background: #7e9be2;
    border-radius: 30px;
    overflow: hidden;

    ::v-deep .el-input {
        background-color: #7e9be2;
    }

    // .search-input[data-v-451d5487] .el-input__inner

    .search-bor {
        position: relative;
    }

    .search-bor:before {
        left: 5px;
    }
    .search-bor:after {
        right: 0;
    }
    .search-bor:before,
    .search-bor:after {
        position: absolute;
        top: 50%;
        content: '';
        width: 2px;
        height: 20px;
        background: rgba(138, 147, 168, 0.16);
        transform: translate(0, -50%);
    }
    ::v-deep .el-input__inner {
        border: none;
        line-height: 62px;
        height: 62px;
        font-size: 16px;
        padding: 0 15px;
        background-color: #7e9be2;
        color: #fff;
        font-size: 18px;
    }

    //修改placeholder提示文字颜色
    ::v-deep input::-webkit-input-placeholder {
        -webkit-text-fill-color: #193d9b;
        font-size: 18px;
    }
    // //修改输入框字体颜色
    // .el-input /deep/ .el-input__inner {
    //     color: red;
    // }

    // 特别提示一点，/deep/ 前后都有空格

    ::v-deep .el-input-group__append {
        background: #3872d6;
        border: none;

        button.el-button {
            // color: white;
            color: white;
            padding: 18px 45px;
            font-size: 20px;
        }
    }
}
</style>